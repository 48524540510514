export const updateMessageAction = (dispatch, message) => {
  return dispatch({
    type: "MESSAGE_UPDATED",
    payload: message,
  });
};

export const updateWalletAction = (dispatch, wallet) => {
  return dispatch({
    type: "WALLET_UPDATED",
    payload: wallet,
  });
};
