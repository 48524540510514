import React, { useContext } from "react";
import "./App.css";
import { updateWalletAction, updateMessageAction } from "./store/actions";
import { Store } from "./store/store-reducer";
import connectMetamask from "./helpers/connectMetamask";
import connectDefiwallet from "./helpers/connectDefiwallet";
import connectWalletConnect from "./helpers/connectWalletConnect";
import * as config from "./config";
import * as utils from "./helpers/utils";

const App = () => {
  const { state, dispatch } = useContext(Store);

  const connect = async (walletType) => {
    updateMessageAction(dispatch, {
      isLoading: true,
      type: "loading",
      text: "Loading wallet...",
    });

    let walletObj;

    switch (walletType) {
      case "metamask":
        walletObj = await connectMetamask();
        break;
      case "defiwallet":
        walletObj = await connectDefiwallet();
        break;
      case "walletConnect":
        walletObj = await connectWalletConnect();
        break;
      default:
        walletObj = await connectMetamask();
    }

    if (walletObj) {
      updateWalletAction(dispatch, {
        type: walletType,
        address: walletObj.address,
        chainId: walletObj.chainId,
        connected: walletObj.connected,
        browserWeb3Provider: walletObj.browserWeb3Provider,
        serverWeb3Provider: walletObj.serverWeb3Provider,
        connectors: [walletObj.wcProvider, walletObj.wcConnector],
      });
    }

    updateMessageAction(dispatch, {
      isLoading: false,
      type: "success",
      text: "Wallet connected",
    });
  };

  const checkAssets = async () => {
    try {
      let crows, raffle, s;

      const royalCrowsBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.royalCrows.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("crows balance", royalCrowsBalance);

      const meerkatsBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.meerkats.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("meerkat balance", meerkatsBalance);

      const meerkatsTreehouseBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.meerkatsTreehouse.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("meerkat treehouse balance", meerkatsTreehouseBalance);

      const meerkatsDegenBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.meerkatsDegen.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("meerkat degen balance", meerkatsDegenBalance);

      const croSkullBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.croSkull.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("croSkull balance", croSkullBalance);

      const lionsBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.lions.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("lions balance", lionsBalance);

      const chimpBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.chimps.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("chimp balance", chimpBalance);

      const croCrowsBalance = await utils.getBalance(
        state.wallet.browserWeb3Provider,
        config.configVars.collections.croCrows.address,
        config.configVars.abi,
        state.wallet.address
      );

      console.log("croCrows balance", croCrowsBalance);

      // const cyberCubsBalance = await utils.getBalance(
      //   state.wallet.browserWeb3Provider,
      //   config.configVars.collections.cyberCubs.address,
      //   config.configVars.abi,
      //   state.wallet.address
      // );

      // console.log("cyberCubs balance", cyberCubsBalance);

      const tierRaffle = [chimpBalance, croCrowsBalance, cyberCubsBalance];

      const tierS = [
        meerkatsBalance,
        croSkullBalance,
        lionsBalance,
        meerkatsTreehouseBalance,
        meerkatsDegenBalance,
      ];

      console.log(tierRaffle, tierS);

      const hasRaffle = tierRaffle.some((bal) => bal > 0);
      const hasS = tierS.some((bal) => bal > 0);
      if (hasRaffle) {
        raffle = true;
      }
      if (hasS) {
        s = true;
      }
      if (royalCrowsBalance > 0) {
        crows = true;
      }

      return { crows, s, raffle };
    } catch (err) {
      console.log(err);
    }
  };

  const signMessage = async (id) => {
    updateMessageAction(dispatch, {
      isLoading: true,
      type: "loading",
      text: "Signing message...",
    });
    const signer = state.wallet.browserWeb3Provider.getSigner();
    const message = await signer.signMessage(id);
    if (message) {
      updateMessageAction(dispatch, {
        isLoading: true,
        type: "loading",
        text: "Checking assets, please wait...",
      });
      console.log(state.wallet.address);
      fetch(
        `https://crows-connect-discord.herokuapp.com/callback/${window.location.pathname.slice(
          1
        )}/${state.wallet.address}`,
        {
          method: "GET",
          mode: "no-cors",
        }
      )
        .then(() => {
          updateMessageAction(dispatch, {
            isLoading: false,
            type: "success",
            text: "You can close that window now and come back to discord",
          });
        })
        .catch((error) => console.log(error));
    } else {
      fetch(
        `https://crows-connect-discord.herokuapp.com/callbackFailure/${window.location.pathname.slice(
          1
        )}`,
        {
          method: "GET",
          mode: "no-cors",
        }
      )
        .then(() => {
          updateMessageAction(dispatch, {
            isLoading: false,
            type: "fail",
            text: "No applicable assets found",
          });
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="container">
      <img
        className="crow-logo"
        src={process.env.PUBLIC_URL + "/assets/img/crow-hero.png"}
        alt=""
      />
      <h1>Crows Connect v1</h1>
      {!state.wallet.connected && <h3>Select the wallet</h3>}
      {state.message && <h3>{state.message.text}</h3>}
      {!state.wallet.connected && (
        <div className="btn-bar">
          <button className="btn" onClick={() => connect("memamask")}>
            {state.wallet.isLoading && (
              <img
                className="loading"
                src={process.env.PUBLIC_URL + "/assets/svg/spinner.svg"}
              />
            )}
            Metamask
          </button>
          <button className="btn" onClick={() => connect("defiwallet")}>
            {state.wallet.isLoading && (
              <img
                className="loading"
                src={process.env.PUBLIC_URL + "/assets/svg/spinner.svg"}
              />
            )}
            Defi Extension
          </button>
          <button className="btn" onClick={() => connect("walletConnect")}>
            {state.wallet.isLoading && (
              <img
                className="loading"
                src={process.env.PUBLIC_URL + "/assets/svg/spinner.svg"}
              />
            )}
            WalletConnect
          </button>
        </div>
      )}
      {state.wallet.connected &&
        state.message.text !==
          "You can close that window now and come back to discord" && (
          <button
            className="btn"
            onClick={() =>
              signMessage(
                "Verify account ownership",
                window.location.pathname.slice(1)
              )
            }
          >
            Sign Message
          </button>
        )}
    </div>
  );
};

export default App;
