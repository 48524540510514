export const configVars = {
  mode: "regular",
  rpcNetwork: {
    rpcUrl: "https://cronos-testnet-3.crypto.org:8545/",
    chainId: 338,
    chainIdHex: "0x152",
    chainName: "Cronos Testnet",
    chainType: "mainnet",
    nativeCurrency: {
      name: "CRO",
      symbol: "CRO",
      decimals: 18,
    },
    blockExplorerUrl: "https://cronos.crypto.org/explorer/testnet3/",
  },
  rpcNetwork_mainnet: {
    rpcUrl: "https://evm-cronos.crypto.org/",
    chainId: 25,
    chainIdHex: "0x19",
    chainName: "Cronos Mainnet Beta",
    chainType: "mainnet",
    nativeCurrency: {
      name: "CRO",
      symbol: "CRO",
      decimals: 18,
    },
    blockExplorerUrl: "https://cronos.crypto.org/explorer/",
  },
  collections: {
    royalCrows: {
      address: "0xb32b3bb699634c7f5ed0b39ce7f2290b0e8cd601",
    },
    chimps: {
      address: "0x562F021423D75A1636DB5bE1C4D99Bc005ccebFe",
      abi: [
        {
          type: "constructor",
          stateMutability: "nonpayable",
          inputs: [
            { type: "uint256", name: "_mintPrice", internalType: "uint256" },
            { type: "string", name: "_baseURIValue", internalType: "string" },
            { type: "uint256", name: "_maxSupply", internalType: "uint256" },
            {
              type: "uint256",
              name: "_royaltiesBips",
              internalType: "uint256",
            },
            {
              type: "uint256",
              name: "_saleStartTimestamp",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "event",
          name: "Approval",
          inputs: [
            {
              type: "address",
              name: "owner",
              internalType: "address",
              indexed: true,
            },
            {
              type: "address",
              name: "approved",
              internalType: "address",
              indexed: true,
            },
            {
              type: "uint256",
              name: "tokenId",
              internalType: "uint256",
              indexed: true,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "ApprovalForAll",
          inputs: [
            {
              type: "address",
              name: "owner",
              internalType: "address",
              indexed: true,
            },
            {
              type: "address",
              name: "operator",
              internalType: "address",
              indexed: true,
            },
            {
              type: "bool",
              name: "approved",
              internalType: "bool",
              indexed: false,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "ChimpBought",
          inputs: [
            {
              type: "uint256",
              name: "tokenId",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "uint256",
              name: "value",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "uint256",
              name: "sellerRevenue",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "uint256",
              name: "royalties",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "address",
              name: "seller",
              internalType: "address",
              indexed: false,
            },
            {
              type: "address",
              name: "buyer",
              internalType: "address",
              indexed: false,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "ChimpNoLongerForSale",
          inputs: [
            {
              type: "uint256",
              name: "tokenId",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "address",
              name: "from",
              internalType: "address",
              indexed: false,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "ChimpsMinted",
          inputs: [
            {
              type: "uint256[]",
              name: "tokenIds",
              internalType: "uint256[]",
              indexed: false,
            },
            {
              type: "address",
              name: "buyer",
              internalType: "address",
              indexed: false,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              type: "address",
              name: "previousOwner",
              internalType: "address",
              indexed: true,
            },
            {
              type: "address",
              name: "newOwner",
              internalType: "address",
              indexed: true,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "PutChimpForSale",
          inputs: [
            {
              type: "uint256",
              name: "tokenId",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "uint256",
              name: "price",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "address",
              name: "from",
              internalType: "address",
              indexed: false,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Transfer",
          inputs: [
            {
              type: "address",
              name: "from",
              internalType: "address",
              indexed: true,
            },
            {
              type: "address",
              name: "to",
              internalType: "address",
              indexed: true,
            },
            {
              type: "uint256",
              name: "tokenId",
              internalType: "uint256",
              indexed: true,
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "UpdateMintPrice",
          inputs: [
            {
              type: "uint256",
              name: "mintPrice",
              internalType: "uint256",
              indexed: false,
            },
            {
              type: "address",
              name: "owner",
              internalType: "address",
              indexed: false,
            },
          ],
          anonymous: false,
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "adminMintChimp",
          inputs: [
            { type: "uint256", name: "amount", internalType: "uint256" },
            { type: "address", name: "recipient", internalType: "address" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "approve",
          inputs: [
            { type: "address", name: "to", internalType: "address" },
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "balanceOf",
          inputs: [{ type: "address", name: "owner", internalType: "address" }],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "string", name: "", internalType: "string" }],
          name: "baseURI",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "payable",
          outputs: [],
          name: "buyChimp",
          inputs: [
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "chimpNoLongerForSale",
          inputs: [
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [
            { type: "bool", name: "isForSale", internalType: "bool" },
            { type: "uint256", name: "tokenId", internalType: "uint256" },
            { type: "address", name: "seller", internalType: "address" },
            { type: "uint256", name: "price", internalType: "uint256" },
          ],
          name: "chimpsForSale",
          inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "chimpsRemainingToAssign",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "address", name: "", internalType: "address" }],
          name: "getApproved",
          inputs: [
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "getMintPrice",
          inputs: [
            { type: "uint256", name: "amount", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [
            { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
            { type: "address[]", name: "sellers", internalType: "address[]" },
            { type: "uint256[]", name: "prices", internalType: "uint256[]" },
          ],
          name: "getOnSaleTokenIds",
          inputs: [
            { type: "uint256", name: "start", internalType: "uint256" },
            { type: "uint256", name: "end", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "bool", name: "", internalType: "bool" }],
          name: "isApprovedForAll",
          inputs: [
            { type: "address", name: "owner", internalType: "address" },
            { type: "address", name: "operator", internalType: "address" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "maxSupply",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "payable",
          outputs: [],
          name: "mintChimp",
          inputs: [
            { type: "uint256", name: "amount", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "mintPrice",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "string", name: "", internalType: "string" }],
          name: "name",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "address", name: "", internalType: "address" }],
          name: "owner",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "address", name: "", internalType: "address" }],
          name: "ownerOf",
          inputs: [
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "putChimpForSale",
          inputs: [
            { type: "uint256", name: "tokenId", internalType: "uint256" },
            { type: "uint256", name: "price", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "renounceOwnership",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "royaltiesBips",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "safeTransferFrom",
          inputs: [
            { type: "address", name: "from", internalType: "address" },
            { type: "address", name: "to", internalType: "address" },
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "safeTransferFrom",
          inputs: [
            { type: "address", name: "from", internalType: "address" },
            { type: "address", name: "to", internalType: "address" },
            { type: "uint256", name: "tokenId", internalType: "uint256" },
            { type: "bytes", name: "_data", internalType: "bytes" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "saleStartTimestamp",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "setApprovalForAll",
          inputs: [
            { type: "address", name: "operator", internalType: "address" },
            { type: "bool", name: "approved", internalType: "bool" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "bool", name: "", internalType: "bool" }],
          name: "supportsInterface",
          inputs: [
            { type: "bytes4", name: "interfaceId", internalType: "bytes4" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "string", name: "", internalType: "string" }],
          name: "symbol",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "tokenByIndex",
          inputs: [{ type: "uint256", name: "index", internalType: "uint256" }],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "tokenOfOwnerByIndex",
          inputs: [
            { type: "address", name: "owner", internalType: "address" },
            { type: "uint256", name: "index", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "string", name: "", internalType: "string" }],
          name: "tokenURI",
          inputs: [
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "totalSupply",
          inputs: [],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "transferFrom",
          inputs: [
            { type: "address", name: "from", internalType: "address" },
            { type: "address", name: "to", internalType: "address" },
            { type: "uint256", name: "tokenId", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "transferOwnership",
          inputs: [
            { type: "address", name: "newOwner", internalType: "address" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "updateBaseURI",
          inputs: [
            { type: "string", name: "_baseURIValue", internalType: "string" },
          ],
        },
        {
          type: "function",
          stateMutability: "nonpayable",
          outputs: [],
          name: "updateMintPrice",
          inputs: [
            { type: "uint256", name: "_mintPrice", internalType: "uint256" },
          ],
        },
        {
          type: "function",
          stateMutability: "view",
          outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
          name: "volume",
          inputs: [],
        },
      ],
    },
    meerkats: {
      address: "0x89dBC8Bd9a6037Cbd6EC66C4bF4189c9747B1C56",
    },
    croSkull: {
      address: "0xF87A517A5CaecaA03d7cCa770789BdB61e09e05F",
    },
    lazyHorse: {
      address: "0xD504ed871d33dbD4f56f523A37dceC86Ee918cb6",
    },
    lions: {
      address: "0xEa1635a0E9344d933DF42c0Fd494d39bcE865Dc4",
    },
    meerkatsTreehouse: {
      address: "0xDC5bBDb4A4b051BDB85B959eB3cBD1c8C0d0c105",
    },
    meerkatsDegen: {
      address: "0xA19bFcE9BaF34b92923b71D487db9D0D051a88F8",
    },
    croCrows: {
      address: "0xE4ab77ED89528d90E6bcf0E1Ac99C58Da24e79d5",
    },
    cyberCubs: {
      address: "0xE3D53fb80c8Cda7Bb9B7207cb6B186172BD323cf",
    },
  },
  abi: [
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
      constant: true,
    },
  ],
};
