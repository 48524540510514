import { ethers } from "ethers";

import * as config from "../config";

export const shortenAddress = (address) => {
  return address.slice(0, 6) + "..." + address.slice(-4);
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const hexToInt = (s) => {
  const bn = ethers.BigNumber.from(s);
  return parseInt(bn.toString());
};

export const reloadApp = () => {
  window.location.reload();
};

// Get the last block number
export const getLastBlockNumber = async (ethersProvider) => {
  return ethersProvider.getBlockNumber();
};

// Get the CRO balance of address
export const getCroBalance = async (serverWeb3Provider, address) => {
  const balance = await serverWeb3Provider.getBalance(address);
  // Balance is rounded at 2 decimals instead of 18, to simplify the UI
  return (
    ethers.BigNumber.from(balance)
      .div(ethers.BigNumber.from("10000000000000000"))
      .toNumber() / 100
  );
};

// get token balance
export const getBalance = async (
  serverWeb3Provider,
  contractAddress,
  contractAbi,
  address
) => {
  // Create ethers.Contract object using the smart contract's ABI
  const readContractInstance = new ethers.Contract(
    contractAddress,
    contractAbi,
    serverWeb3Provider
  );
  const contractResponse = await readContractInstance["balanceOf"](address);
  return ethers.BigNumber.from(contractResponse).toNumber();
};

export const getTotalSupply = async (serverWeb3Provider) => {
  const contractAbi = CrowsContract.abi;
  const readContractInstance = new ethers.Contract(
    config.configVars.token.address,
    contractAbi,
    serverWeb3Provider
  );
  const totalSupply = await readContractInstance["totalSupply"]();
  return ethers.BigNumber.from(totalSupply).toNumber();
};

// Generate a ethers.Contract instance of the contract object
// together with a signer that will trigger a transaction
// approval in the wallet whenever it is called by the Dapp
export const getWriteContractInstance = async (browserWeb3Provider) => {
  const ethersProvider = browserWeb3Provider;
  const contractAbi = CrowsContract.abi;
  // Create ethers.Contract object using the smart contract's ABI
  const readContractInstance = new ethers.Contract(
    config.configVars.token.address,
    contractAbi,
    ethersProvider
  );
  // Add a signer to make the ethers.Contract object able
  // to craft transactions
  const fromSigner = ethersProvider.getSigner();
  return readContractInstance.connect(fromSigner);
};
