import React, { createContext, useReducer } from "react";

const initialState = {
  message: {
    isLoading: false,
    type: null,
    text: null,
  },
  wallet: {
    type: null,
    address: null,
    chainId: null,
    browserWeb3Provider: null,
    serverWeb3Provider: null,
    connected: false,
    balance: null,
    connectors: [],
  },
};

export const Store = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "MESSAGE_UPDATED":
      return { ...state, message: action.payload };
    case "WALLET_UPDATED":
      return { ...state, wallet: action.payload };
    default:
      return { state };
  }
};

export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  );
};
